import React from 'react';
import Mockup from '../../assets/images/PhoneMock1.png';
import appStore from '../../assets/images/AppStore.png';
import play from '../../assets/images/GooglePlay.png';

const GetPerfectCandidate = ({ id = '' }) => {
    return (
        <>
            <div className='container px-2 sm:px-2 md:px-2   mx-auto' id={id}>
                <div className='relative h-full md:h-[306px] lg:h-[330px]  bg-gradient-to-r from-[#0D6D86] to-[#214E92] rounded-[48px] grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div className='flex justify-center'>
                        <img
                            src={Mockup}

                            className='mr-0 rtl:md:mr-20 ltr:md:ml-20 -mt-24 md:-mt-[5rem] lg:-mt-[7rem] md:h-[386px] 
                            lg:h-[442px]'
                            alt='phoneMockup'
                        />
                    </div>

                    <div className='py-14 px-10 flex flex-col justify-center align-middle'>
                        <p className='flex justify-start text-left text-4xl font-bold capitalize text-white'>
                            Get the perfect Candidate easily!
                        </p>

                        <p className='flex justify-start pt-2 font-light text-left text-white'>
                            Create a free account, complete your profile, and get matched with your Company.
                        </p>

                        <div className='flex gap-2 my-7'>
                            <a href='/'>
                                <button>
                                    <img src={appStore} alt='app_store_logo' />
                                </button>
                            </a>

                            <a href='/'>
                                <button>
                                    <img src={play} alt='play_store_logo' />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GetPerfectCandidate;
