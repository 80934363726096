import React from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
  {
    code: 'en',
    name: 'EN',
    dir: 'ltr',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'AR',
    dir: 'rtl',
    country_code: 'sa',
  },
];

const LanguageChange = () => {
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    localStorage.setItem('language', e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <form >
      <select className='font-semibold text-xs bg-transparent outline-0 cursor-pointer' onChange={handleChange} value={i18n.language}>
        {languages.map((lan) => (
          <option
            key={lan?.name}
            value={lan?.code}
            disabled={i18n.language===lan.code}
            className='font-semibold text-sm'
          >
            {lan?.name}
          </option>
        ))}
      </select>
    </form>
  );
};

export default LanguageChange;
