import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Footer from './footer/Footer';
import Header from './header/Header';
import Home from '../pages/home/Home';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsAndCondition from '../pages/terms-and-condition/TermsAndCondition';
import AccountDeletionManual from '../pages/account-deletion-manual/AccountDeletionManual';

const BaseLayout = () => {
    return (
        <BrowserRouter>
            <Header />
            <main>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/terms-and-condition' element={<TermsAndCondition />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/account-deletion-manual' element={<AccountDeletionManual />} />
                </Routes>
            </main>
            <Footer />
        </BrowserRouter>
    );
};

export default BaseLayout;
