/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const ArrowRight = ({
  height = 20,
  width = 20,
  className = "",
  color = "",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.04348 3L6 4.05077L11.908 9.999L6 15.9492L7.04348 17L12.952 11.05L12.9565 11.0559L14 10.0052L7.04348 3Z"
      fill="#8E9AA6"
    />
  </svg>
);

ArrowRight.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default ArrowRight;
