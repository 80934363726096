/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const LocationIcon = ({
  height = 20,
  width = 20,
  className = "",
  color = "",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 3C12.7614 3 15 4.90458 15 8C15 8.98228 14.4965 10.2474 13.8059 11.517L13.6551 11.7891L13.3772 12.2676L13.2336 12.5052L12.9393 12.9752L12.6389 13.4354L12.336 13.8824L11.8856 14.5209L11.5934 14.9203L11.3122 15.2948L10.9192 15.8025L10.5721 16.2354L10.2838 16.5822L10.013 16.8911L9.93489 16.9722L9.9 17L9.88844 16.993L9.83329 16.9384L9.67738 16.7611L9.44456 16.4792L9.03581 15.96L8.54143 15.3013L8.132 14.7317L7.84749 14.3222L7.55798 13.8927L7.26714 13.4466L6.97859 12.9871L6.696 12.5176L6.42301 12.0414C5.62208 10.606 5 9.12467 5 8C5 4.87592 7.23858 3 10 3ZM10 4.5C7.91839 4.5 6.5 5.84733 6.5 8C6.5 8.65926 6.88486 9.73975 7.58099 11.0338L7.72435 11.2954L7.98116 11.7441L8.24888 12.1894L8.5237 12.6274L8.80179 13.0543L9.07937 13.4664L9.35 13.8562L9.74109 14.4009L9.922 14.641L10.1128 14.394L10.3828 14.0347L10.6599 13.6562L11.0942 13.0411L11.3829 12.6154L11.668 12.1792L11.9498 11.7294L12.0801 11.5143L12.3487 11.0521C13.0947 9.72965 13.5 8.64061 13.5 8C13.5 5.86462 12.0694 4.5 10 4.5ZM10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10Z"
      fill="#8E9AA6"
    />
  </svg>
);

LocationIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default LocationIcon;
