/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const MessageIcon = ({
  height = 20,
  width = 20,
  className = "",
  color = "",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 17H1V3H20V17ZM10.5092 12.1989L2.5 8.448V15.5H18.5V8.449L10.5092 12.1989ZM18.5 4.5H2.5V6.79L10.5 10.52L18.5 6.79V4.5Z"
      fill="#8E9AA6"
    />
  </svg>
);

MessageIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default MessageIcon;
