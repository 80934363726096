/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';

const CompanyIcon = ({ height = 81, width = 80, className = '', color = '' }) => (
    <svg width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.05" cx="40.625" cy="40" r="40" fill="#00807F"/>
<path d="M27.8625 30.25V26.6875H53.2125V30.25H27.8625ZM28.0125 53.275V43.6H26.0625V40.075L27.8625 32.5H53.175L54.975 40.075V43.6H52.9875V53.275H49.4625V43.6H43.65V53.275H28.0125ZM31.575 49.75H40.125V43.6H31.575V49.75ZM29.625 40.075H51.4125H29.625ZM29.625 40.075H51.4125L50.475 36.025H30.5625L29.625 40.075Z" fill="#00807F"/>
</svg>

    

);

CompanyIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
};

export default CompanyIcon;
