/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';

const CandidateIcon = ({ height = 81, width = 80, className = '', color = '' }) => (
    <svg width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="40.125" cy="40" r="40" fill="#00807F"/>
    <g clip-path="url(#clip0_209_1036)">
    <path d="M41.755 28L49 35.245V52H31V28H41.755ZM43 25H31C29.35 25 28 26.35 28 28V52C28 53.65 29.35 55 31 55H49C50.65 55 52 53.65 52 52V34L43 25ZM40 43C41.65 43 43 41.65 43 40C43 38.35 41.65 37 40 37C38.35 37 37 38.35 37 40C37 41.65 38.35 43 40 43ZM46 48.145C46 46.93 45.28 45.85 44.17 45.37C42.895 44.815 41.485 44.5 40 44.5C38.515 44.5 37.105 44.815 35.83 45.37C34.72 45.85 34 46.93 34 48.145V49H46V48.145Z" fill="#00807F"/>
    </g>
    <defs>
    <clipPath id="clip0_209_1036">
    <rect width="36" height="36" fill="white" transform="translate(22 22)"/>
    </clipPath>
    </defs>
    </svg>
    

);

CandidateIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
};

export default CandidateIcon;
