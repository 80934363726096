import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AccountDeletionManual = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container mx-auto flex flex-col justify-center align-middle'>
      <div className='py-12'>
        <div className=''>
          <h1 className='text-4xl'>{t('OPUS – Account Deletion Manual')}</h1>
        </div>

        <div className='mt-10 mx-auto max-w-[996px]'>
          <div className='flex flex-col gap-5'>
            <p className='text-start'>
              {t("We understand that circumstances change, and you may choose to delete your account. We value your privacy and aim to make this process as straightforward as possible. Follow the steps below to delete your account:")}
            </p>

            <div className='text-start'>
              <h3 className='text-xl'>{t('Step 1: Accessing Account Settings')}</h3>
              <p className='mt-2'>
                {t(
                  "Log in to your account on our app using your credentials. Once logged in, navigate to your profile or account settings. You can typically find this by clicking on your profile picture for Initiating Account Deletion Click on the 'Delete Account' or 'Close Account' button/link. This action may prompt a confirmation dialog box or require you to re-enter your password for security purposes. Follow the provided instructions or prompts to confirm the deletion process."
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('Step 2: Confirmation of Account Deletion')}</h3>
              <p className='mt-2'>
                {t(
                  'Once you confirm the deletion, your account will enter a deletion queue. This process may take a short while to complete, during which your account will be deactivated and scheduled for permanent deletion. You might receive a confirmation email or notification confirming the successful initiation of the account deletion process.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('Step 3: Confirmation of Account Deletion Completion')}</h3>
              <p className='mt-2'>
                {t(
                  'Your account and associated data will be permanently deleted from our systems after the specified deletion period. During this time, your account will not be accessible. You will receive a final notification or confirmation when the deletion process is complete. After this, you will no longer have access to your account or any associated data.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('Additional Information:')}</h3>
              <p className='mt-2'>
                {t(
                  'Reactivation: Please note that after initiating the deletion process, you might not be able to reactivate your account or recover any data associated with it. If you encounter any issues or have further questions regarding the account deletion process, feel free to contact our support team. We appreciate your understanding and thank you for being a part of our community.'
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletionManual;
