/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';

const JobIcon = ({ height = 81, width = 80, className = '', color = '' }) => (
    <svg width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="40.875" cy="40" r="40" fill="#00807F"/>
    <path d="M27.7125 54.55C26.7375 54.55 25.9062 54.2 25.2188 53.5C24.5312 52.8 24.1875 51.9625 24.1875 50.9875V33.6625C24.1875 32.6875 24.5312 31.8563 25.2188 31.1688C25.9062 30.4813 26.7375 30.1375 27.7125 30.1375H33.2625V26.8375C33.2625 25.8625 33.6062 25.025 34.2938 24.325C34.9813 23.625 35.8125 23.275 36.7875 23.275H43.4625C44.4375 23.275 45.2687 23.625 45.9562 24.325C46.6437 25.025 46.9875 25.8625 46.9875 26.8375V30.1375H52.5375C53.5125 30.1375 54.35 30.4813 55.05 31.1688C55.75 31.8563 56.1 32.6875 56.1 33.6625V50.9875C56.1 51.9625 55.75 52.8 55.05 53.5C54.35 54.2 53.5125 54.55 52.5375 54.55H27.7125ZM27.7125 50.9875H52.5375V33.6625H27.7125V50.9875ZM36.7875 30.1375H43.4625V26.8375H36.7875V30.1375ZM27.7125 50.9875V33.6625V50.9875Z" fill="#00807F"/>
    </svg>
    

    

);

JobIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
};

export default JobIcon;
