
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import BaseLayout from './layouts/BaseLayout';

function App() {

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'))
  }, [i18n]);

  return (
    <div className="App">
      <BaseLayout />
    </div>
  );
}

export default App;
