import React from 'react';
import GetUsKnowSlider from './GetUsKnowSlider';
import Button from '../atoms/Button';

const GetUsKnow = ({ id = '' }) => {
  return (
    <div className='p-3 mb-[8rem]' id={id}>
      <div className='md:w[50%] lg:w-[50%]  mx-auto'>

        <p className='font-bold text-3xl md:text-[3.5rem] xl:text-[3.5rem] leading-none py-10 tracking-wide'>
          Get To
          <span className='text-[#00807F] tracking-wide px-1'>

            Know Us <span className='text-black'>.</span>{' '}
          </span>
          It Was A Long Journey Here!
        </p>
      </div>

      <GetUsKnowSlider />

      <div>
        <p className='text-[#8E9AA6] text-center pb-[14px]'>
          Create a free account, complete your profile, and get matched with your dream job.
        </p>
        <Button title='View All' className='bg-[#214E92] text-white' />
      </div>
    </div>
  );
};

export default GetUsKnow;
