import React from 'react';
import CandidateIcon from '../../assets/svg/CandidateIcon';
import CompanyIcon from '../../assets/svg/CompanyIcon';
import JobIcon from '../../assets/svg/JobIcon';


import UserPlusIcon from '../../assets/svg/UserPlusIcon';


const CounterSection = ({ id = " " }) => {
    return (
        <div id={id} className='container w-[70%] my-16 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
            <div className='mx-auto'>
                <CandidateIcon className='mx-auto' ></CandidateIcon>
                <p className='text-darkBlue text-5xl font-semibold py-2'>1234</p>
                <p className='text-slateGray py-2'>Candidates</p>
            </div>
            <div className='mx-auto'>
                <UserPlusIcon className='mx-auto' />
                <p className='text-darkBlue text-5xl font-semibold py-2'>1234</p>
                <p className='text-slateGray py-2'>Vacancies</p>
            </div>
            <div className='mx-auto'>
                <CompanyIcon className='mx-auto'></CompanyIcon>
                <p className='text-darkBlue text-5xl font-semibold py-2'>1234</p>
                <p className='text-slateGray py-2'>Companies</p>
            </div>
            <div className='mx-auto'>
                <JobIcon className='mx-auto'></JobIcon>
                <p className='text-darkBlue text-5xl font-semibold py-2'>1234</p>
                <p className='text-slateGray py-2'>New Jobs</p>
            </div>
        </div>
    );
};

export default CounterSection;