import React from 'react'
import Slider from "react-slick";
import slider1 from '../../assets/images/slider-1.png'
import slider2 from '../../assets/images/slider-2.png'
import slider3 from '../../assets/images/slider-3.png';
import slider4 from '../../assets/images/slider-4.png'
import "slick-carousel/slick/slick.css";       // Import slick carousel CSS
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/custom_slider.css';

const GetUsKnowSlider = () => {

  const sliderImages = [slider1, slider2, slider3, slider4, slider1, slider2];
  const settings = {
    rtl: true,
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    swipeToSlide: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },

    ]
  };


  return (
    <div className='my-10'>
      <div className="px-[13px]">
        <Slider {...settings}>

          {
            sliderImages.map((image, i) => i % 2 === 0 ? <div className="" key={i}><img className='w-[85%] md:w-[80%] mt-16' src={image} alt="cc" /></div> :
              <div className='' key={i}><img className='w-[85%] md:w-[80%] mb-16' src={image} alt="cc" /></div>

            )
          }

        </Slider>
      </div>

    </div>
  )
}

export default GetUsKnowSlider

