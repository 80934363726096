import React from 'react';
import { useTranslation } from 'react-i18next';

const Button = ({title='',className=''}) => {
  const {t}=useTranslation();
  return (
    <button type='button' className={`rounded-md font-medium text-sm leading-[18px] max-w-[149px] text-center px-8 py-3.5 ${className}`}>{t(`${title}`)}</button>
  );
};

export default Button;