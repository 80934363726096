import React from 'react';
import PrimaryNavbar from './PrimaryNavbar';

const Header = () => {
    return (
        <div>
            <PrimaryNavbar />
        </div>
    );
}

export default Header;
