import React from 'react';
import CounterSection from '../../components/organizations/CounterSection';
import GetInTouch from '../../components/organizations/GetInTouch';
import GetPerfectCandidate from '../../components/organizations/GetPerfectCandidate';
import HeroSection from '../../components/organizations/HeroSection';
import LearMore from '../../components/organizations/LearMore';
import GetUsKnow from '../../components/organizations/GetUsKnow';

function Home() {
    return (
        <div>
            <HeroSection id="hero" />
            <CounterSection id="counter" />
            <GetUsKnow id="gallery" />
            <GetPerfectCandidate id="download" />
            <LearMore id="about-us" />
            <GetInTouch id="contact-us" />
        </div>
    );
}

export default Home;
