import React from 'react';
import Button from '../atoms/Button';
import CEO from '../../assets/images/CEO.png';
import Partner from '../../assets/images/Partner.png';
import Partner2 from '../../assets/images/Partner-2.png';

const LearMore = ({ id = '' }) => {
    return (
        <div id={id} className='container mx-auto py-16 px-0 md:px-20'>
            <p className='font-bold md:text-5xl text-[2rem] py-10'>
                Learn More About <span className='text-[#00807F]'>OPUS</span>
            </p>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 py-10 '>
                <div className='col-span-2 w-full px-4 sm:px-0 md:px-0 sm:w-3/4 md:3/4'>
                    <p className='font-bold text-center md:text-left text-2xl  pt-2'>How We Started</p>
                    <p className='text-sm text-justify  sm:text-center md:text-left text-[#8E9AA6]'>
                        Create a free account, complete your profile, and get matched with your dream job.Create
                        a free account, complete your profile, and get matched with your dream job.Create a free
                        account, complete your profile, and get matched with your dream job.Create a free
                        account, complete your profile, and get matched with your dream job.
                        <br /> <br /> Create a free account, complete your profile, and get matched with your
                        dream job. Create a free account, complete your profile, and get matched with your dream
                        job.
                    </p>
                </div>
                <div className='grid place-content-center h-full'>
                    <Button title='View All' className='bg-darkBlue text-white'></Button>
                </div>
            </div>

            <div className='grid px-2 grid-cols-1 md:grid-cols-3 gap-10 '>
                <div className='relative rounded-lg overflow-hidden'>
                    <img className='w-full h-[500px] object-cover' src={CEO} alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>Kathryn Murphy</p>
                            <p className='text-[16px] text-white'>CEO</p>
                        </div>
                    </div>
                </div>

                <div className='relative rounded-lg  overflow-hidden'>
                    <img
                        className='w-full h-[500px] object-cover'
                        src={Partner}
                        alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>Kathryn Murphy</p>
                            <p className='text-[16px] text-white'>Partner</p>
                        </div>
                    </div>
                </div>

                <div className='relative rounded-lg overflow-hidden'>
                    <img
                        className='w-full h-[500px] object-cover'
                        src={Partner2}
                        alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>Kathryn Murphy</p>
                            <p className='text-[16px] text-white'>Partner</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearMore;
