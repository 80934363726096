import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container mx-auto flex flex-col justify-center align-middle'>
      <div className='py-12'>
        <div className=''>
          <h1 className='text-4xl'>{t('Privacy Policy')}</h1>
          <h5 className='text-lg text-gray-500 mt-3'>
            {t('Usage and Privacy Policy - OPUS Hiring Platform')}
          </h5>
        </div>

        <div className='mt-10 mx-auto max-w-[996px]'>
          <div className='flex flex-col gap-5'>
            <div className='text-start'>
              <h3 className='text-xl'>{t('1. Introduction')}</h3>
              <p className='mt-2'>
                {t(
                  "Welcome to the OPUS Hiring Platform ('OPUS'). This policy outlines how OPUS collects, uses, and protects your personal information. By using OPUS, you agree to the terms of this policy."
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('2. Information Collection and Use')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS collects personal information such as your name, contact details, employment history, and educational background. This information is used to create and maintain your profile, facilitate job matching, and communicate with you regarding job opportunities.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('3. Job Applications')}</h3>
              <p className='mt-2'>
                {t(
                  'When you apply for a job through OPUS, your application and personal information will be shared with the relevant employers or recruiters. OPUS is not responsible for how these third parties handle your information.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('4. Data Security')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS implements security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and OPUS cannot guarantee absolute security.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('5. Cookies and Tracking Technologies')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS uses cookies and similar tracking technologies to enhance your user experience and gather information about how you use the platform. You can manage your cookie preferences through your browser settings.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('6. Third-Party Websites and Services')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS may contain links to third-party websites or services. This policy does not cover the practices of these third parties, and you should review their respective privacy policies before providing any personal information.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('7. Information Retention')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS retains your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('8. Access, Correction, and Deletion')}</h3>
              <p className='mt-2'>
                {t(
                  'You have the right to access, correct, or delete your personal information stored by OPUS. To exercise these rights, please contact us using the provided contact information.'
                )}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t('9. Updates to the Policy')}</h3>
              <p className='mt-2'>
                {t(
                  'OPUS may update this policy from time to time. The updated policy will be posted on the platform, and your continued use of OPUS will constitute acceptance of the updated policy.'
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
