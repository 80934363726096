/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const Instagram = ({ height = 20, width = 20, className = "", color = "" }) => (
  <svg width={width}
  height={height}
  viewBox={`0 0 ${width} ${height}`}
  className={className}
  fill="none"
  stroke={` ${color}`}
  strokeWidth="2"
  xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.0637 3C4.82572 3 3 4.82572 3 7.0637V12.9363C3 15.1743 4.82572 17 7.0637 17H12.9363C15.1743 17 17 15.1743 17 12.9363V7.0637C17 4.82572 15.1743 3 12.9363 3H7.0637ZM7.0637 4.07692H12.9363C14.5916 4.07692 15.9231 5.40625 15.9231 7.0637V12.9363C15.9231 14.5916 14.5938 15.9231 12.9363 15.9231H7.0637C5.40835 15.9231 4.07692 14.5938 4.07692 12.9363V7.0637C4.07692 5.40835 5.40625 4.07692 7.0637 4.07692ZM14.0385 5.15385C13.5925 5.15385 13.2308 5.51562 13.2308 5.96154C13.2308 6.40745 13.5925 6.76923 14.0385 6.76923C14.4844 6.76923 14.8462 6.40745 14.8462 5.96154C14.8462 5.51562 14.4844 5.15385 14.0385 5.15385ZM10 6.23077C7.92398 6.23077 6.23077 7.92398 6.23077 10C6.23077 12.076 7.92398 13.7692 10 13.7692C12.076 13.7692 13.7692 12.076 13.7692 10C13.7692 7.92398 12.076 6.23077 10 6.23077ZM10 7.30769C11.4934 7.30769 12.6923 8.50661 12.6923 10C12.6923 11.4934 11.4934 12.6923 10 12.6923C8.50661 12.6923 7.30769 11.4934 7.30769 10C7.30769 8.50661 8.50661 7.30769 10 7.30769Z" fill="#8E9AA6"/>
</svg>

);

Instagram.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default Instagram;
