import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TermsAndCondition = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container mx-auto flex flex-col justify-center align-middle'>
      <div className='py-12'>
        <div className=''>
          <h1 className='text-4xl'>{t("Terms and condition")}</h1>
          <h5 className='text-lg text-gray-500 mt-3'>
            {t("Terms and Conditions - OPUS Hiring Platform")}
          </h5>
        </div>

        <div className='mt-10 mx-auto max-w-[996px]'>
          <div className='flex flex-col gap-5'>
            <p className='text-start'>
              {t("These Terms and Conditions ('Agreement') govern your use of the OPUS Hiring Platform ('OPUS'). By accessing or using OPUS, you agree to be bound by this Agreement. This Agreement is compliant with Saudi Arabian laws and regulations. If you do not agree with any part of this Agreement, you should not use OPUS.")}
            </p>

            <div className='text-start'>
              <h3 className='text-xl'>{t("1. User Eligibility")}</h3>
              <p className='mt-2'>
                {t("OPUS is intended for use by individuals who are 18 years of age or older. By using OPUS, you represent and warrant that you are at least 18 years old.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("2. User Responsibilities")}</h3>
              <p className='mt-2'>
                {t("You are responsible for the accuracy and completeness of the information you provide on OPUS. You agree to provide true, current, and complete information and to update your information promptly if there are any changes.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("3. Job Listings and Applications")}</h3>
              <p className='mt-2'>
                {t("OPUS provides a platform for employers and recruiters to list job opportunities and for job seekers to apply for those opportunities. OPUS does not guarantee the availability, accuracy, or suitability of any job listing. It is your responsibility to evaluate and verify the details of the job listing before applying.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("4. User Content")}</h3>
              <p className='mt-2'>
                {t("By submitting any content, including but not limited to resumes, job applications, or reviews, you grant OPUS a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("5. Intellectual Property Rights")}</h3>
              <p className='mt-2'>
                {t("OPUS and its associated logos, trademarks, and other intellectual property are owned by OPUS and are protected by Saudi Arabian laws and regulations. You agree not to use, reproduce, modify, or distribute any intellectual property of OPUS without prior written consent.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("6. Limitation of Liability")}</h3>
              <p className='mt-2'>
                {t("OPUS does not endorse or guarantee the accuracy, completeness, or reliability of any content posted on the platform, including job listings, user profiles, or user-generated content. OPUS shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use OPUS.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("7. Privacy and Data Protection")}</h3>
              <p className='mt-2'>
                {t("OPUS collects and processes personal information in accordance with its Privacy Policy, which is incorporated into this Agreement. By using OPUS, you consent to the collection, use, and processing of your personal information as described in the Privacy Policy.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("8. Indemnification")}</h3>
              <p className='mt-2'>
                {t("You agree to indemnify and hold OPUS, its affiliates, officers, directors, employees, and agents harmless from any claims, demands, losses, liabilities, costs, or expenses, including reasonable attorneys' fees, arising out of or related to your use of OPUS or any violation of this Agreement.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("9. Termination")}</h3>
              <p className='mt-2'>
                {t("OPUS may, at its sole discretion, terminate or suspend your access to OPUS without prior notice for any reason, including but not limited to a violation of this Agreement.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("10. Severability")}</h3>
              <p className='mt-2'>
                {t("If any provision of this Agreement is deemed invalid or unenforceable, the remaining provisions shall continue in full force and effect.")}
              </p>
            </div>

            <div className='text-start'>
              <h3 className='text-xl'>{t("11. Governing Law and Jurisdiction")}</h3>
              <p className='mt-2'>
                {t("This Agreement shall be governed by and construed in accordance with the laws of Saudi Arabia. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Saudi Arabia.")}
              </p>
            </div>

            <div className='text-start'>
              <p className='mt-2'>
                {t("Please note that the above terms and conditions are a general template and may need to be reviewed and customized by legal professionals to ensure compliance with specific Saudi Arabian laws and regulations and to reflect the unique aspects of OPUS and its operations.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
