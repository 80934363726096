import { useTranslation } from 'react-i18next';
import { headerRoutes } from '../../routes/headerRoutes';

const MobileScreen = () => {
    const { i18n } = useTranslation();
    return (
        <div className='relative'>
            <div className='py-10 block md:hidden absolute  top-4 left-0 right-0 bottom-0  bg-lightWhite  z-50 rounded-xl h-screen'>
                <ul className='items-start gap-y-3 flex flex-col px-10 space-y-2'>
                    {headerRoutes?.map((item) => (
                        <li key={item?.id} className='text-slateGray'>
                            {i18n.language === 'en' ? item?.name : item?.name_ar}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MobileScreen;
